var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "buzz-in-mobile" },
    [
      _vm.allowFamilyFeudInput && _vm.isViewerTurn && _vm.isFamilyFeud
        ? _c("TextInput", { attrs: { mode: _vm.mode, mission: _vm.mission } })
        : [
            !_vm.isFamilyFeud && _vm.instructions
              ? _c("ResizableText", {
                  staticClass: "buzz-in-mobile__top",
                  attrs: { message: _vm.instructions },
                })
              : _vm._e(),
            _c(
              "transition",
              { attrs: { name: "buzz-in-mobile__transition" } },
              [
                _vm.isButtonVisible
                  ? _c(
                      "div",
                      { staticClass: "buzz-in-mobile__bottom" },
                      [
                        _c("BuzzInAction", {
                          attrs: { disabled: !_vm.isActive },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (ref) {
                                  var onBuzzIn = ref.onBuzzIn
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "buzz-in-mobile__btn",
                                        class: {
                                          "buzz-in-mobile__btn--active":
                                            _vm.isActive,
                                        },
                                        on: { click: onBuzzIn },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "buzz-in-mobile__btn-cnt",
                                          },
                                          [
                                            _vm.isActive
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "buzz-in-mobile__btn-text",
                                                  },
                                                  [_vm._v(" Buzzer ")]
                                                )
                                              : _c("BuzzInBtn", {
                                                  staticClass:
                                                    "buzz-in-mobile__btn-badge",
                                                  attrs: {
                                                    buzzCount:
                                                      +_vm.isScribe &&
                                                      _vm.getBuzzInIndex(
                                                        _vm.viewer.id
                                                      ),
                                                    isWaiting: !_vm.isScribe,
                                                  },
                                                }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            2557944838
                          ),
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }