<template>
  <div class="buzz-in-mobile">
    <TextInput
      v-if="allowFamilyFeudInput && isViewerTurn && isFamilyFeud"
      :mode="mode"
      :mission="mission"
    />
    <template v-else>
      <ResizableText
        v-if="!isFamilyFeud && instructions"
        class="buzz-in-mobile__top"
        :message="instructions"
      />
      <transition name="buzz-in-mobile__transition">
        <div v-if="isButtonVisible" class="buzz-in-mobile__bottom">
          <BuzzInAction #default="{ onBuzzIn }" :disabled="!isActive">
            <div
              class="buzz-in-mobile__btn"
              :class="{ 'buzz-in-mobile__btn--active': isActive }"
              @click="onBuzzIn"
            >
              <div class="buzz-in-mobile__btn-cnt">
                <div v-if="isActive" class="buzz-in-mobile__btn-text">
                  Buzzer
                </div>

                <BuzzInBtn
                  v-else
                  class="buzz-in-mobile__btn-badge"
                  :buzzCount="+isScribe && getBuzzInIndex(viewer.id)"
                  :isWaiting="!isScribe"
                />
              </div>
            </div>
          </BuzzInAction>
        </div>
      </transition>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import ResizableText from "@/components/GroupTeams/Common/Games/ResizableText.vue"
import BuzzInAction from "@/components/GroupTeams/Common/Games/BuzzIn/BuzzInAction"
import BuzzInBtn from "@/components/GroupTeams/Common/Games/BuzzIn/BuzzInBtn.vue"

import { computed, toRefs } from "@vue/composition-api"
import useStore from "@/use/useStore"
import useBuzzIn from "@/use/useBuzzIn"
import useFamilyFeud from "@/use/useFamilyFeud"
import MediaType from "@shared/enums/Media"

export default {
  name: "MobileBuzzIn",
  components: {
    BuzzInBtn,
    BuzzInAction,
    ResizableText,
    TextInput: () =>
      import("@/components/GroupTeams/Common/Games/TextInput.vue")
  },
  props: {
    mission: Object,
    mode: String
  },
  setup(props) {
    const { mission } = toRefs(props)
    const { store } = useStore()
    const viewer = computed(() => store.getters["auth/user"])
    const { getIndex: getBuzzInIndex, buzz } = useBuzzIn()
    const {
      isViewerTurn,
      allowInput: allowFamilyFeudInput,
      isFamilyFeud
    } = useFamilyFeud(mission, buzz)

    const isScribe = computed(() => store.getters.isScribe)

    const isAssetVisible = computed(() => {
      const { media } = store.getters.gameStatus
      return Object.values(MediaType).some(type => type === media)
    })

    const isButtonVisible = computed(() => {
      return isFamilyFeud.value
        ? isScribe
        : isAssetVisible.value && !getBuzzInIndex(viewer.value.id)
    })

    const instructions = computed(() =>
      isFamilyFeud.value && !isScribe.value
        ? "Waiting for you turn..."
        : mission.value?.instructions
    )

    const isActive = computed(() =>
      isFamilyFeud.value
        ? !getBuzzInIndex(viewer.value?.id) && isScribe.value
        : true
    )

    return {
      viewer,
      isScribe,
      isActive,
      instructions,
      isButtonVisible,
      getBuzzInIndex,
      isViewerTurn,
      allowFamilyFeudInput,
      isFamilyFeud
    }
  }
}
</script>

<style scoped lang="scss">
.buzz-in-mobile {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  &__top {
    flex: 1;
  }

  &__bottom {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 16px;
  }

  &__btn {
    width: max(26vh, 26vw);
    max-width: min(55vh, 55vw);
    border-radius: 50%;
    border-width: 3px;
    border-style: solid;
    border-color: rgba($color-white, 0.4);
    box-shadow: inset 0px 4px 40px rgba(0, 0, 0, 0.7);
    position: relative;
    overflow: hidden;

    &::before {
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: rgba(#f5665d, 0.4);
      z-index: -1;
    }

    &--active {
      border-color: $color-white;

      &::before {
        background: #f5665d;
      }
    }

    &::after {
      content: "";
      display: block;
      padding-top: 100%;
    }
  }

  &__btn-cnt {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__btn-text {
    font-family: "Right Grotesk";
    font-style: normal;
    font-weight: 900;
    font-size: 48px;
    line-height: 58px;
    text-transform: uppercase;
  }

  &__btn-badge {
    width: 90%;
  }

  &__transition {
    &-enter-active,
    &-leave-active {
      transition: all ease-in-out 0.8s;
      position: relative;
      max-height: 300px;
    }
    &-enter,
    &-leave-to {
      opacity: 0;
      max-height: 0;
    }
  }
}
</style>
